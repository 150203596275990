export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Boxes = () => import('../../components/Boxes.vue' /* webpackChunkName: "components/boxes" */).then(c => wrapFunctional(c.default || c))
export const DragAndDropFile = () => import('../../components/DragAndDropFile.vue' /* webpackChunkName: "components/drag-and-drop-file" */).then(c => wrapFunctional(c.default || c))
export const FileUploadDragDrop = () => import('../../components/FileUploadDragDrop.vue' /* webpackChunkName: "components/file-upload-drag-drop" */).then(c => wrapFunctional(c.default || c))
export const FilterForm = () => import('../../components/FilterForm.vue' /* webpackChunkName: "components/filter-form" */).then(c => wrapFunctional(c.default || c))
export const GamingInfo = () => import('../../components/GamingInfo.vue' /* webpackChunkName: "components/gaming-info" */).then(c => wrapFunctional(c.default || c))
export const GoBack = () => import('../../components/GoBack.vue' /* webpackChunkName: "components/go-back" */).then(c => wrapFunctional(c.default || c))
export const InfoCards = () => import('../../components/InfoCards.vue' /* webpackChunkName: "components/info-cards" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const PhoneTelInput = () => import('../../components/PhoneTelInput.vue' /* webpackChunkName: "components/phone-tel-input" */).then(c => wrapFunctional(c.default || c))
export const QuestionCard = () => import('../../components/QuestionCard.vue' /* webpackChunkName: "components/question-card" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const DesktopBidProject = () => import('../../components/Desktop/BidProject.vue' /* webpackChunkName: "components/desktop-bid-project" */).then(c => wrapFunctional(c.default || c))
export const DesktopCareer = () => import('../../components/Desktop/Career.vue' /* webpackChunkName: "components/desktop-career" */).then(c => wrapFunctional(c.default || c))
export const DesktopFooter = () => import('../../components/Desktop/Footer.vue' /* webpackChunkName: "components/desktop-footer" */).then(c => wrapFunctional(c.default || c))
export const DesktopNav = () => import('../../components/Desktop/Nav.vue' /* webpackChunkName: "components/desktop-nav" */).then(c => wrapFunctional(c.default || c))
export const DesktopNews = () => import('../../components/Desktop/News.vue' /* webpackChunkName: "components/desktop-news" */).then(c => wrapFunctional(c.default || c))
export const DesktopNewsAndAnnoun = () => import('../../components/Desktop/NewsAndAnnoun.vue' /* webpackChunkName: "components/desktop-news-and-announ" */).then(c => wrapFunctional(c.default || c))
export const MobileBidProject = () => import('../../components/Mobile/BidProject.vue' /* webpackChunkName: "components/mobile-bid-project" */).then(c => wrapFunctional(c.default || c))
export const MobileCareer = () => import('../../components/Mobile/Career.vue' /* webpackChunkName: "components/mobile-career" */).then(c => wrapFunctional(c.default || c))
export const MobileFooter = () => import('../../components/Mobile/Footer.vue' /* webpackChunkName: "components/mobile-footer" */).then(c => wrapFunctional(c.default || c))
export const MobileNav = () => import('../../components/Mobile/Nav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c))
export const MobileNews = () => import('../../components/Mobile/News.vue' /* webpackChunkName: "components/mobile-news" */).then(c => wrapFunctional(c.default || c))
export const MobileNewsAndAnnoun = () => import('../../components/Mobile/NewsAndAnnoun.vue' /* webpackChunkName: "components/mobile-news-and-announ" */).then(c => wrapFunctional(c.default || c))
export const VuetifyPhoneInput = () => import('../../components/Vuetify/PhoneInput.vue' /* webpackChunkName: "components/vuetify-phone-input" */).then(c => wrapFunctional(c.default || c))
export const VuetifyAllCountry = () => import('../../components/Vuetify/all-country.js' /* webpackChunkName: "components/vuetify-all-country" */).then(c => wrapFunctional(c.default || c))
export const VuetifyUtils = () => import('../../components/Vuetify/utils.js' /* webpackChunkName: "components/vuetify-utils" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
